import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PropTypes from "prop-types";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import { AuthContext } from "../../../context/Auth";

import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
} from "@mui/material";
import NavItem from "./NavItem";
import { RxCrossCircled } from "react-icons/rx";
import { useTranslation } from "react-i18next";

const useStyles = (theme) => ({
  mobileDrawer: {
    width: 256,
    background: "linear-gradient(90deg, #1F9874, #3ACAB6)",
    position: "fixed",
  },
  desktopDrawer: {
    "& .MuiPaper-root": {
      backgroundColor: "red",
    },
    zIndex: "1",
    marginTop: "70px !important",
    top: "76px",
    width: "260px",
    height: "calc(100% - 125px)",
    margin: "5px 10px 10px 15px",
    background: "linear-gradient(135deg, #1F9874, #3ACAB6)",
    position: "fixed",
    boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    marginLeft: "13px",
  },
  logoutButton: {
    color: "#fff",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "5px",
    left: "17px",
    background: "#fff",
    fontWeight: "400",
    fontSize: "13px",
  },
  dialog: {
    "& .MuiDialog-paper": {
      background: "white !important",
    },
  },
  logoutimgicon: {
    border: "1px solid white",
    background: "white",
    color: "red",
    width: "100px",
    height: "70px",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// import statements

const getPermissionsFromCookie = () => {
  console.log("Current cookies:", document.cookie);
  const permissionsCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("permissions="));

  if (!permissionsCookie) {
    console.log("Permissions cookie not found");
    return null;
  }

  const permissionsString = permissionsCookie.split("=")[1];

  try {
    return permissionsString
      ? JSON.parse(decodeURIComponent(permissionsString))
      : null;
  } catch (error) {
    console.error("Error parsing permissions cookie:", error);
    return null;
  }
};

const NavBar = ({ onMobileClose, openMobile, tabView, setSelectedTab }) => {
  const classes = useStyles();
  const location = useLocation();
  const [isLogout, setIsLogout] = useState(false);
  const { handleLogOut } = useContext(AuthContext);
  const { t } = useTranslation();

  // const handleLogOut = () => {
  //   sessionStorage.removeItem("adminToken");
  //   navigate("/");
  // };

  // Get permissions from cookie
  const permissions = getPermissionsFromCookie();
  console.log(permissions);
  const sections = [
    {
      items: [
        {
          title: t("dashboard"),
          icon: DashboardOutlinedIcon,
          href: "/dashboard",
          tabview: "Arbitrage",
        },
        {
          title: t("subAdminManagement"),
          icon: SupervisorAccountOutlinedIcon,
          href: "/subAdmin",
          tabview: "Arbitrage",
        },
        {
          title: t("employerManagement"),
          icon: SupervisorAccountOutlinedIcon,
          href: "/employerListing",
        },
        {
          title: t("candidateManagement"),
          icon: SupervisorAccountOutlinedIcon,
          href: "/candidateListing",
        },
        {
          title: t("jobManagement"),
          icon: WorkOutlineOutlinedIcon,
          href: "/jobListing",
        },
        {
          title: t("industryManagement"),
          icon: WorkOutlineOutlinedIcon,
          href: "/jobcategorymanagement",
        },
        {
          title: t("blogManagement"),
          icon: BookOutlinedIcon,
          href: "/blogManagement",
        },
        {
          title: t("subscriptionManagement"),
          icon: SubscriptionsOutlinedIcon,
          href: "/SubscriptionListing",
        },
        {
          title: t("transactionManagement"),
          icon: AccountBalanceWalletOutlinedIcon,
          href: "/transactionListing",
        },
        {
          title: t("contentManagement"),
          icon: FolderCopyOutlinedIcon,
          href: "/staticContentListing",
        },
        {
          title: t("skillManagement"),
          icon: BorderColorOutlinedIcon,
          href: "/skills",
        },
        {
          title: t("ticketManagement"),
          icon: BorderColorOutlinedIcon,
          href: "/listtickets",
        },
        {
          title: t("locationManagement"),
          icon: LocationOnOutlinedIcon,
          href: "/locations",
        },
        {
          title: t("myAccount"),
          icon: AccountBoxOutlinedIcon,
          href: "/profile",
          tabview: "Arbitrage",
        },
      ],
    },
  ];
  const filteredSections = sections.map((section) => ({
    ...section,
    items: section.items.filter((item) => {
      if (permissions && permissions?.allPermissions) {
        // If allPermissions is true, allow all routes
        return true;
      }
      return (
        item.href === "/profile" ||
        (permissions &&
          permissions?.permissions.some(
            (p) => p.route === item.href && p.granted
          ))
      );
    }),
  }));

  const renderNavItems = ({ items, pathname, depth = 0, setSelectedTab }) => (
    <List disablePadding>
      {items?.map((item) => {
        const key = item.title + depth;
        const isActive = pathname.includes(item.href);
        return item.items ? (
          <NavItem
            depth={depth}
            icon={item.icon}
            info={item.info}
            key={key}
            open={Boolean(isActive)}
            title={item.title}
            isActive={isActive}
          >
            {renderNavItems({
              depth: depth + 1,
              pathname,
              items: item.items,
              setSelectedTab,
            })}
          </NavItem>
        ) : (
          <NavItem
            depth={depth}
            href={item.href}
            icon={item.icon}
            info={item.info}
            key={key}
            title={item.title}
            setSelectedTab={(item) => setSelectedTab(item)}
            isActive={isActive}
          />
        );
      })}
    </List>
  );

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="sideMenuBox">
            {filteredSections.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                  setSelectedTab,
                })}
              </List>
            ))}
          </Box>
        </Box>
        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
          style={{ color: "white", textAlign: "center" }}
        >
          <ExitToAppIcon style={{ marginRight: "5px" }} />
          &nbsp; {t("logoutTitle")}
        </Button>
        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dialog}
            open={isLogout}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Box>
                  <RxCrossCircled className={classes.logoutimgicon} />
                </Box>
                <Typography
                  variant="h4"
                  color="primary"
                  style={{ marginTop: "20px", color: "black" }}
                >
                  {t("logoutTitle")}
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ marginTop: "20px", color: "black" }}
                >
                  {t("logoutConfirmation")}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <Box mt={2} style={{ marginTop: "20px" }}>
                <Button
                  className="modelbtn"
                  variant="containedPrimary"
                  onClick={() => setIsLogout(false)}
                >
                  {t("noButton")}
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  variant="containedPrimary"
                  onClick={() => handleLogOut()}
                >
                  {t("yesButton")}
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          open={openMobile}
          onClose={onMobileClose}
          variant="temporary"
          sx={{
            "& .MuiPaper-root": {
              background: "linear-gradient(90deg, #1F9874, #3ACAB6)",
              color: "white", // Example text color
            },
          }}
        >
          <Box pt={2} pb={2}>
            {content}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
          sx={{
            "& .MuiPaper-root": {
              marginTop: "89px !important",
              background: "linear-gradient(90deg, #1F9874, #3ACAB6)",
              color: "white", // Example text color
              boxShadow: "0px 0px 53px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              width: "260px",
              height: "calc(100% - 125px)",
              margin: "5px 4px 10px 8px",
              position: "fixed",
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  setSelectedTab: PropTypes.func.isRequired,
};

export default NavBar;


